const awsauth = {
    Auth: {
        region: 'ap-southeast-2',
        userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
        userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
          oauth: {
            domain: process.env.VUE_APP_COGNITO_APP_DOMAIN,
            scope: [
              'email',
              'openid',
              'profile',
             // 'aws.cognito.signin.user.admin'
            ],
            redirectSignIn:
             process.env.VUE_APP_APP_URL,
            redirectSignOut:
              process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT,
            responseType: 'code'
          },
      }
};

export default awsauth;
