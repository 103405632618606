import requests from './../requests'

const state = {
    cognitoInfo: {},
    loggedIn: false,
    loadingState: true,
    errorLoadingState: false,
    windowWidth: 900,
    expandView: false,
    currentUploads: 0,
    uploads: [],
    localRecURLs: [],
    userDetails: null,
    userPlan: null,
    plans: [],
    currentDurationUploading: 0,
    currentSizeUploading: 0,
    accountMode: "",
    hasExpired: false,
    freeTrial: false,
    accountStatus: "",
    termsAccepted: null,
    premiumUser : false,
    devMode: false,
}

const getters = {
    ACCOUNT_STATUS: state => {
    return state.accountStatus
    },
    WIDTH : state => {
        return state.windowWidth
    },
    COGNITO_INFO: state => {
      return state.cognitoInfo
    },
    CURRENT_UPLOADS: state => {
      return state.uploads
    },
    EXPAND_VIEW: state => {
      return state.expandView
    },
    USER_ID: state => {
      return state.sub
    },
    USER_DETAILS: state => {
      return state.userDetails
    },
    REMAINING_QUOTA: state => {
      return state.userDetails.TranscribeQuota - (state.userDetails.PendingTranscribeQuota + state.currentDurationUploading)
    },
    REMAINING_SIZE_QUOTA: state => {
      if (state.userDetails) {
        return state.userDetails.UploadQuota - (state.userDetails.UsedUploadQuota + state.currentSizeUploading)
      } else {
        return 0
      }
    },
    USER_PLAN: state => {
      return state.userPlan
    },
    PLANS: state => {
      return state.plans
    },
    CURRENT_DURATION_UPLOADING: state => {
      return state.currentDurationUploading
    },
    CURRENT_SIZE_UPLOADING: state => {
      return state.currentSizeUploading
    },
    ACCOUNT_MODE: state => {

      let mode = ""

      switch (true) {

          case state.accountStatus == "LOCKED":
              mode = "LOCKED"
              break
          case state.hasExpired :
              mode = "TRIAL_EXPIRED"
              break
           case state.freeTrial :
              mode = "TRIAL"
              break
          default:
              mode = "NORMAL"
              break
      }

      return mode
    },
    DAYS_REMAINING: state => {

      if (state.userDetails.FTDaysRemaining) {
        return Math.ceil(state.userDetails.FTDaysRemaining)
      } else {
        return 0
      }

    },
    ACCESS_DENIED: state => {

      let access = false

        if (state.accountStatus == "LOCKED" || state.hasExpired) {
            access = true
          }

      return access

    },
    LOGGED_IN: state => {
      return state.loggedIn
    },
    TERMS_ACCEPTED: state => {
      return state.termsAccepted
    },
    LOCAL_REC_URLS: state => {
      return state.localRecURLs
    },
    PREMIUM_USER: state => {
      return state.premiumUser
    },
    DEV_MODE: state => {
      return state.devMode
    }
}

const mutations = {
  SET_LOGGED_IN : (state, newValue) => {
    state.loggedIn = newValue;
  },
  SET_LOGGED_OUT: (state) => {
    state.loggedIn = false
    state.cognitoInfo = {}
  },
  SET_COGNITO_INFO: (state, newValue) => {
    state.cognitoInfo = newValue
  },
  SET_JWT: (state, jwt) => {
    state.token = jwt
  },
  INCREMENT_UPLOADS: (state, increment) => {
    state.currentUploads = state.currentUploads + increment
  },
  INCREMENT_PENDING: (state, increments) => {

    if (increments.duration > 0) {
      const duration = Math.ceil(increments.duration / 60)
      state.currentDurationUploading = state.currentDurationUploading +  duration
    }

    if (increments.size > 0) {
      state.currentSizeUploading = state.currentSizeUploading +  increments.size
    }

  },
  SET_WIDTH: (state, width) => {
    state.windowWidth = width
  },
  SET_EXPAND: (state, bool) => {
    state.expandView = bool
  },
  SET_USER_DETAILS: (state, details) => {
    state.userDetails = details

    state.freeTrial = details.FreeTrial
    state.hasExpired = details.HasExpired
    state.accountStatus = details.AccountStatus
    state.termsAccepted = details.TermsAccepted

  },
  SET_USER_PLAN: (state, planDetails) => {
    state.userPlan = planDetails

    if (planDetails.Tier > 0) {
      state.premiumUser = true
    }

  },
  SET_PLANS: (state, plans) => {
    state.plans = plans
  },
  SET_NEW_STATUS: (state, newStatus) => {
    state.accountStatus = newStatus
  },
  SET_JWT_EXPIRY: (state, expiry) => {
    state.jwtExpiry = expiry
  },
  UPDATE_UPLOADS: (state, upload) => {

      // Create the file information - id, name, progress
      const uploadObj = {
        id: upload.file.id,
        name: upload.file.meta.Title,
        progress: upload.progress.bytesUploaded / upload.progress.bytesTotal
      }

      const upIndex = state.uploads.findIndex( currentUpload => {
        return uploadObj.id == currentUpload.id
      })

      if (upIndex > -1 && uploadObj.progress == 1) {
        state.uploads.splice(upIndex, 1)

      } else if (upIndex > -1 ) {
        state.uploads.splice(upIndex, 1, uploadObj)

      } else if (uploadObj.progress == 1) {
        // This step is needed because uppy fires an event when updating has finished twice sometimes
        return

      } else {
        state.uploads.push(uploadObj)
      }

  },
  ADD_LOCAL_REC_URL: (state, localURL) =>  {
    state.localRecURLs.push(localURL)
  },
  TOGGLE_DEV_MODE: (state, bool) => {
    state.devMode = bool
  }
}

const actions = {
  SET_LOGGED_IN: ({commit}, newValue) => {
    commit('SET_LOGGED_IN', newValue)
  },
  SET_LOGGED_OUT: ({commit}) => {
    commit('SET_LOGGED_OUT')
  },
  SET_COGNITO_INFO: ({commit}, newValue) => {
    commit('SET_COGNITO_INFO', newValue)
  },
  SET_JWT: ({commit}, jwt) => {
    commit('SET_JWT', jwt)
  },
  INCREMENT_UPLOADS: ({commit}, change) => {

      let inc = 0

      if (change == "add") {
        inc = 1
      } else if (change == "minus") {
        inc = -1
      }

      commit('INCREMENT_UPLOADS', inc )

  },
  INCREMENT_PENDING: ({commit}, change) => {

    commit('INCREMENT_PENDING', change )

  },
  SET_WIDTH: ({commit}, width) => {
      commit('SET_WIDTH', width)
  },
  SET_EXPAND: ({commit}, bool)=> {
      commit('SET_EXPAND', bool)
  },
  SET_USER_DETAILS: async ({dispatch, commit}) => {

    const response = await requests.getUserDetails()
    if (response.status == 200) {
        commit('SET_USER_DETAILS', response.data.data)
          dispatch('SET_USER_PLAN', response.data.data.PlanID)
    }
  },
  SET_USER_PLAN: async ({commit}, planID) => {

  const response = await requests.getUserPlan(planID)
  if (response.status == 200) {
      commit('SET_USER_PLAN', response.data.data)
    }

  },
  SIGNUP_USER: async ({commit}, planID) => {

    const response = await requests.signUpUser(planID)
    if (response.status == 200) {
        commit('SET_USER_DETAILS', response.data.data)
      }

  },
  SET_PLANS: async ({commit}) => {

    const response = await requests.getPlans()
    if (response.status == 200) {
        commit('SET_PLANS', response.data)
    }
  },
  REQUEST_CANCELLATION: async ({commit}, accRequest) => {

    const response = await requests.requestCancellation(accRequest)
    if (response.status == 200) {
      commit('SET_NEW_STATUS', 'PENDING_CANCEL')
    }
  },
  REQUEST_CHANGE: async ({commit}, accRequest) => {

    const response = await requests.requestChange(accRequest)
    if (response.status == 200) {
      commit('SET_NEW_STATUS', 'PENDING_CHANGE')
    }
  },
  UPDATE_UPLOADS: ({commit}, upload) => {

    commit('UPDATE_UPLOADS', upload)
  },
  ADD_LOCAL_REC_URL: ({commit}, recObject) => {
    // format:
    // 1. collectionID
    // 2. recordingID
    // 3. URL
    commit('ADD_LOCAL_REC_URL', recObject)
  },
  TOGGLE_DEV_MODE: ({commit}, bool) => {
    commit('TOGGLE_DEV_MODE', bool)
  }
,
}

  export default {
    state,
    getters,
    mutations,
    actions
  }