import { event } from 'vue-analytics'

export default {
    gaError: async function (type, errorCode) {

        const actions = {
            UPLOAD_ERROR: 'upload_error',
            UPLOAD_RESTRICTED: 'upload_restricted'
        }

        switch (type) {
            case actions.UPLOAD_ERROR:
                this.errorUpload(errorCode)
                break
            case actions.UPLOAD_RESTRICTED:
                this.errorUploadRestricted(errorCode)
                break
        }

    },
    gaCollection: async function (type) {
        const actions = {
            NEW: 'new',
            EDIT: 'edit',
            DELETE: 'delete'
        }

        switch (type) {
            case actions.NEW:
                this.newColl()
                break
            case actions.EDIT:
                this.editColl()
                break
            case actions.DELETE:
                this.delColl()
                break
        }

    },
    gaRecording: async function (type, recording) {

        const actions = {
            NEW: 'new',
            EDIT: 'edit',
            DELETE: 'delete',
            NOTE_UPDATE: 'noteUpdate',
            SPEED_SLIDER: 'speedSlider',
            TOGGLE_CAPTIONS: 'toggleCaptions',
            FLAGS: 'flags'
        }

        switch (type) {
            case actions.NEW:
                this.newRec(recording)
                break
            case actions.EDIT:
                this.editRec()
                break
            case actions.DELETE:
                this.delRec()
                break
            case actions.NOTE_UPDATE:
                this.notesUpdate(recording)
                break
            case actions.SPEED_SLIDER:
                this.speedSlider(recording)
                break
            case actions.TOGGLE_CAPTIONS:
                this.toggleCaptions()
                break
            case actions.FLAGS:
                this.flags()
                break
        }

        return
    },
    gaHighlight: async function (type, highlight) {

        const actions = {
            NEW: 'new',
            EDIT: 'edit',
            DELETE: 'delete'
        }

        switch (type) {
            case actions.NEW:
                this.newHighlight(highlight)
                break
            case actions.EDIT:
                this.editHighlight()
                break
            case actions.DELETE:
                this.delHighlight()
                break
        }

        return
    },
    gaCapture: async function (type, mediaType, duration) {

        const actions = {
            CAPTURE: 'capture',
            SAVE: 'save',
        }

        switch (type) {
            case actions.CAPTURE:
                this.newCapture(mediaType)
                break
            case actions.SAVE:
                this.newCaptureSave(mediaType, duration)
                break
        }

        return
    },

    gaTranscript: async function (type, label) {

        const actions = {
            EXPORT: 'export',
            EXPORT_QUOTE: 'exportQuote',
            EDIT_QUOTE: 'editQuote',
            SEARCH: 'search',
            FLAG: 'flagEdit'
        }

        switch (type) {
            case actions.EXPORT:
                this.exportTranscript()
                break
            case actions.EXPORT_QUOTE:
                this.exportQuoteTranscript()
                break
            case actions.EDIT_QUOTE:
                this.editQuoteTranscript(label)
                break
            case actions.SEARCH:
                this.searchTranscript()
                break
            case actions.FLAG:
                this.flagEdit(label)
        }

        return
    },
    gaUser: async function (type) {

        const actions = {
            SIGNUP: 'signup',
            GOOGLE_SIGNUP: 'googleSignup',
            // EDIT: 'edit',
            // DELETE: 'delete'
        }

        switch (type) {
            case actions.SIGNUP:
                this.newSignUp('Email Signup')
                break
            case actions.GOOGLE_SIGNUP:
                this.newSignUp('Google Signup')
                break
        }

        return
    },
    gaShare: async function (type) {

        const actions = {
            NEW_SHARE: 'newShare',
            EDIT: 'edit',
            DELETE: 'delete'
        }

        switch (type) {
            case actions.NEW_SHARE:
                this.newShare()
                break
        }

        return
    },
    gaLanguage: async function (type, langCode) {

        const actions = {
            LANG_CHANGE: 'langChange',
        }

        switch (type) {
            case actions.LANG_CHANGE:
                this.languageChange(langCode)
                break
        }

        return
    },
    gaMedia: async function (type, mediaType) {

        const actions = {
            PLAY: 'play',
            PAUSE: 'pause'
        }

        switch (type) {
            case actions.PLAY:
                this.mediaPlay(mediaType)
                break
            case actions.PAUSE:
                this.mediaPause(mediaType)
                break
        }

        return
    },

    // COLLECTION HELPERS
    newColl: function () {

        event({
            eventCategory: 'Collection',
            eventAction: 'New Collection',
        })
    },
    editColl: function () {
        event({
            eventCategory: 'Collection',
            eventAction: 'Edit Collection',
        })
    },
    delColl: function () {
        event({
            eventCategory: 'Collection',
            eventAction: 'Delete Collection',
        })
    },
    // RECORDING HELPERS
    newRec: function (recording) {

        const transcribed = recording.Transcribe ? 1 : 0

        event({
            eventCategory: 'Recording',
            eventAction: 'New Recording',
            eventLabel: recording.MediaDuration,
            eventValue: transcribed,
        })
    },
    notesUpdate: function (recording) {

        // const transcribed = recording.Transcribe ? 1 : 0

        event({
            eventCategory: 'Recording',
            eventAction: 'Notes Edit',
            eventLabel: recording.MediaType,
            // eventValue: transcribed,
        })
    },
    speedSlider: function (speed) {

        event({
            eventCategory: 'Recording',
            eventAction: 'Speed Slider',
            eventLabel: speed,
        })

    },
    toggleCaptions: function () {

        event({
            eventCategory: 'Recording',
            eventAction: 'Toggle Captions'
        })

    },
    flags: function () {

        event({
            eventCategory: 'Recording',
            eventAction: 'Flag Change'
        })

    },
    //TRANSCRIPT HELPERS
    newTranscript: function (recording) {

        const mediaType = recording.MediaType == 'audio/mp3' ? 'Audio' : 'Video'

        event({
            eventCategory: 'Transcription',
            eventAction: "New " + mediaType,
            eventLabel: recording.ItemID,
            eventValue: parseInt(Math.ceil(recording.MediaDuration / 60)),
        })

    },
    exportTranscript: function () {

        event({
            eventCategory: 'Transcription',
            eventAction: "Export",
        })

    },
    exportQuoteTranscript: function (label) {

        event({
            eventCategory: 'Transcription',
            eventAction: 'Export Quote',
            eventLabel: label
        })

    },
    editQuoteTranscript: function () {

        event({
            eventCategory: "Transcription",
            eventAction: "Edit Quote",
        })
    },
    searchTranscript: function () {

        event({
            eventCategory: "Transcription",
            eventAction: "Search Transcript",
        })

    },
    flagEdit: function (label) {

        event({
            eventCategory: "Transcription",
            eventAction: "Edit Flags",
            eventLabel: label
        })
    },
    // SHARING
    newShare: function () {

        event({
            eventCategory: 'Share',
            eventAction: 'New Share',
        })

    },
    // REC HELPERS
    editRec: function () {
        event({
            eventCategory: 'Recording',
            eventAction: 'Edit',
        })
    },
    delRec: function () {
        event({
            eventCategory: 'Recording',
            eventAction: 'Delete',
        })
    },
    // HIGHLIGHT HELPERS
    newHighlight: function (highlight) {

        const duration = highlight.TimeEnd - highlight.TimeStart
        // const tagNum = highlight.Tags ? highlight.Tags.length : 0

        event({
            eventCategory: 'Highlight',
            eventAction: 'New',
            eventLabel: highlight.ItemID,
            eventValue: parseInt(duration),
        })
    },
    editHighlight: function () {
        event({
            eventCategory: 'Highlight',
            eventAction: 'Edit',
        })
    },
    delHighlight: function () {
        event({
            eventCategory: 'Highlight',
            eventAction: 'Delete',
        })
    },
    // USER HELPERS
    newSignUp: function (label) {

        event({
            eventCategory: 'User',
            eventAction: 'Sign Up',
             eventLabel: label,
            // eventValue: parseInt(),
        })
    },
    // ERROR HELPERS
    errorUpload: function (fileType) {

        // console.log(fileType)

        event({
            eventCategory: 'Error',
            eventAction: 'Upload Error',
            eventLabel: fileType,
        })
    },
    errorUploadRestricted: function (fileType) {

        event({
            eventCategory: 'Error',
            eventAction: 'Upload Restricted',
            eventLabel: fileType,
        })
    },
    newCapture: function (mediaType) {

        event({
            eventCategory: 'Capture',
            eventAction: 'New',
            eventLabel: mediaType,
        })

    },
    newCaptureSave: function (mediaType, duration) {

        event({
            eventCategory: 'Capture',
            eventAction: 'Upload',
            eventLabel: mediaType,
            eventValue: duration,
        })

    },
    // Langauge
    languageChange: function (languageCode) {

        event({
            eventCategory: 'Language',
            eventAction: 'Change',
            eventLabel: languageCode,
        })
    },
    mediaPlay: function (type) {

        event({
            eventCategory: 'Media',
            eventAction: 'Play',
            eventLabel: type,
        })

    },
    mediaPause: function (type) {

        event({
            eventCategory: 'Media',
            eventAction: 'Pause',
            eventLabel: type,
        })

    }
}


