import requests from './../requests'
// import EventBus from './../eventBus'

const state = {
    recordingDetails: {},
    highlights: [],
    newModal: false,
    editModal: false,
    editingHighlight: {
        id: '',
        title: '',
        description: '',
        start: 0,
        end: 0,
        tags: []
    },
    videoDuration: 0,
    currentTime: 0,
    sliderMin: 100,
    sliderMax: 700,
    transcriptStatus: null,
    transcript: [],
    loadingRec: 0,
    recLoaded: true,
    speakerNames: null,
    recordingNotes: null,
    recProcessStatus: '',
    plyrState:'',
    highlightHover: {},
    highlightPlaying: null,
    recFlags: []
}

const getters = {
    REC_DETAILS: state => {
        return state.recordingDetails
    },
    REC_LOCATION: state => {
        return state.recordingDetails.MediaLocation
    },
    HIGHLIGHTS : state => {
        return state.highlights
    },
    TAGS : state => {

        if (!state.highlights) {
            return []
        }

        let tags = []
        for (let i = 0; i < state.highlights.length; i++) {
            let highlight = state.highlights[i]

            if (highlight.Tags) {
                for (let j = 0; j < highlight.Tags.length; j++) {
                    tags.push(highlight.Tags[j])
                }
            }
          }
        const distinctTags = [... new Set(tags)]
        return distinctTags
    },
    MODAL : state => {
        if (state.newModal == true || state.editModal == true) {
            return true
        } else
            return false
    },
    GET_EDIT_HIGHLIGHT : state => {
        return state.editingHighlight
    },
    GET_DURATION: state => {
        return state.videoDuration
    },
    GET_CURRENT_TIME: state => {
        return state.currentTime
    },
    GET_MIN: state => {
        return state.sliderMin
    },
    GET_MAX: state => {
        return state.sliderMax
    },
    GET_EDITING: state => {
        return state.editModal ? true : false
    },
    GET_PENDING: state => {
        return state.pendingUploads
    },
    GET_TRANSCRIPT: state => {
        return state.transcript
    },
    REC_INFO_STATUS: state => {
        return state.loadingRec

    },
    REC_LOADED: state => {
        return state.recLoaded
    },
    READY_TRANSCRIPT: state => {

        // Validates to see if the transcript is valid

        // If there are more than one dialogue entry, we assume it's fine
        if (state.transcript.length > 1) {
            return true

        } else if (state.transcript.length == 1) {

            // if there is only one entry, we validate to see that it's not empty
            // a hacky way to avoid the a fault in the conversion script/JSON from transscriber
            if (state.transcript[0].Dialogue == null) {
                return false
            } else {
                return true
            }

        } else {
            return false
        }

    },
    TRAN_STATUS: state => {
        let status = ""

        switch (true) {

            case state.transcriptStatus == 'COMPLETED' :
                status = "ready"
                break
             case state.transcriptStatus == 'FAILED' :
                status = "error"
                break
            case state.recordingDetails.Transcribe == false:
                status = "none"
                break
            case !state.transcriptStatus || state.transcriptStatus == 'IN_PROGRESS':
                status = "processing"
                break
        }

        return status
    },
    SPEAKERS : state => {

        if (!state.transcript) {
            return []
        }

        let speakers = []

        state.transcript.forEach(function(dialogue) {
            speakers.push(dialogue.Speaker)
            }
        )

        const uniqueSpeakers = [... new Set(speakers)]
        return uniqueSpeakers
    },
    SPEAKER_NAMES: state => {
        return state.speakerNames
    },
    REC_PROCESS_STATUS: state => {
        return state.recProcessStatus
    },
    REC_FLAGS: state => {
        return state.recFlags
    },
    HL_HOVER: state => {
        return state.highlightHover
    },
    HL_PLAYING: state => {
        return state.highlightPlaying
    },
    PLYR_STATE: state => {
        return state.plyrState
    }
}

const mutations = {
    SET_RECORDING_DETAILS: (state, details) => {
        state.recordingDetails = details
        state.transcribe = details.Transcribe
    },
    SET_RECORDING_NOTES: (state, notes) => {
        state.recordingDetails.Notes = notes
    },
    SET_HIGHLIGHTS: (state, highlights) => {
        state.highlights = highlights
    },
    DELETE_HIGHLIGHT: (state, index) => {

        // console.log("deleteing index", index, "from", state.highlights)
        state.highlights.splice(index, 1)
        // console.log("done", state.highlights)

    },
    SET_SPEAKER_NAMES: (state, speakerNames) => {
        state.speakerNames = speakerNames
    },
    NEW_HL_MODAL: (state) => {
        state.newModal = true
    },
    CLOSE_HL_MODAL: (state) => {
        state.newModal = false
        state.editModal = false
    },
    SET_EDIT: (state, highlight) => {
        state.editingHighlight = highlight
        state.editModal = true
    },
    SET_DURATION: (state, duration) => {
        state.videoDuration = duration
    },
    SET_CURRENT_TIME: (state, currentTime) => {
        state.currentTime = currentTime
    },
    SET_SLIDER_RANGE: (state, values) => {
        state.sliderMin = values[0]
        state.sliderMax = values[1]
    },
    SET_TRANSCRIPT: (state, transcript) => {
        state.transcript = transcript
    },
    UPDATE_TRANSCRIPT_QUOTE: (state, update) => {

        state.transcript.splice(update.index, 1, update.quote)

    },
    // SPLIT_QUOTE: (state, update) => {

    //     update.Quotes[0].ID = update.Quotes[0].ID + "_" + Math.random().toString(36).substring(5)
    //     update.Quotes[1].ID = update.Quotes[1].ID + "_" + Math.random().toString(36).substring(5)

    //     state.transcript.splice(update.index, 1, update.Quotes[0], update.Quotes[1])

    // },
    RENAME_SPEAKERS: (state) => {

        if (state.speakerNames) {

            state.transcript.forEach(dialogue => {

                let alias = state.speakerNames.find(speakerName => {
                    return dialogue.Speaker == speakerName.Speaker
                })

                if (alias) {
                    let newName = ""

                    if (!alias.Alias && alias.Speaker) {
                        newName = "Speaker " + (parseInt(alias.Speaker[alias.Speaker.length -1]) + 1)
                    } else if (!alias.Alias && !alias.Speaker) {
                        newName = "Speaker"
                    } else {
                        newName = alias.Alias
                    }
                    dialogue.DisplayName = newName
                }
            })
        }
    },
    DELETE_RECORDING_DETAILS: (state) => {
        state.recordingDetails = {}
        state.transcript = []
    },
    UPDATE_LOADING: (state, value) => {
        state.loadingRec = value
    },
    SET_CURRENT_RECORDING_TSTATUS: (state, value) => {
        state.transcriptStatus = value
    },
    SET_CURRENT_FLAGS: (state, value) => {
        state.recFlags = value
    },
    SET_LOADED: (state, value) => {
        state.recLoaded = value
    },
    SET_REC_PROCESS_STATUS: (state, status) => {
        state.recProcessStatus = status
    },
    SET_HL_HOVER: (state, highlight) => {
        state.highlightHover = highlight
    },
    SET_PLYR_STATE: (state, plyrState) => {
        state.plyrState = plyrState
    },
    SET_HL_PLAYING: (state, highlight) => {
        state.highlightPlaying = highlight
    }
}

const actions = {
    SET_LOADED: ({commit}, value) => {
        commit('SET_LOADED', value)
    },
    NEW_HL_MODAL: ({commit}) => {
        commit('NEW_HL_MODAL')
    },
    CLOSE_HL_MODAL: ({commit}) => {
        commit('CLOSE_HL_MODAL')
    },
    SET_EDIT: ({commit}, highlight) => {
        commit('SET_EDIT', highlight)
    },
    SET_DURATION: ({commit}, duration) => {
        commit('SET_DURATION', duration)
    },
    SET_CURRENT_TIME: ({commit}, currentTime) => {
        commit('SET_CURRENT_TIME', currentTime)
    },
    SET_SLIDER_RANGE: ({commit}, values) => {
        commit('SET_SLIDER_RANGE', values)
    },
    SET_CURRENT_RECORDING: async ({commit}, params) => {

        commit('UPDATE_LOADING', 0)

        const response = await requests.getRecording(params.collID, params.recID)
        if (response.status == 200) {
            commit('SET_HIGHLIGHTS', response.data.data.Highlights)
            delete response.data.data.Highlights

            commit('SET_SPEAKER_NAMES', response.data.data.SpeakerNames)
            delete response.data.data.SpeakerNames

            commit('RENAME_SPEAKERS')
            commit('SET_RECORDING_DETAILS', response.data.data)
            commit('SET_CURRENT_RECORDING_TSTATUS', response.data.data.TranscribeStatus)
            commit('SET_CURRENT_FLAGS', response.data.data.Flags)
            commit('UPDATE_LOADING', 1)
        }
    },
    SET_CURRENT_RECORDING_TSTATUS: async ({commit}, params) => {

        const response = await requests.getRecording(params.collID, params.recID)
        if (response.status == 200) {
            commit('SET_CURRENT_RECORDING_TSTATUS', response.data.data.TranscribeStatus)
        }
    },

    UPDATE_CURR_RECORDING: async ({commit}, params) => {

        const response = await requests.updateRecording(params.collID, params.itemID, params.recording)
        if (response.status == 200) {
            delete response.data.data.Highlights
            commit('SET_SPEAKER_NAMES', response.data.data.SpeakerNames)
            delete response.data.data.SpeakerNames
            commit('RENAME_SPEAKERS')
            commit('SET_RECORDING_DETAILS', response.data.data)
            commit('SET_CURRENT_RECORDING_TSTATUS', response.data.data.TranscribeStatus)

        }
    },
    UPDATE_CURR_RECORDING_NOTES: async ({commit}, params) => {

        const response = await requests.updateRecording(params.collID, params.itemID, params.recording)
        if (response.status == 200) {
            commit('SET_RECORDING_NOTES', response.data.data.Notes)
        }
    },
    DELETE_CURR_RECORDING: async ({commit}, params) => {

        const response = await requests.deleteRecording(params.collID, params.recID)
        if (response.status == 200) {
            commit('DELETE_RECORDING_DETAILS')
        }
    },
    NEW_HIGHLIGHT: async ({commit}, payload) => {
        const response = await requests.createHighlight(payload.params.collID, payload.params.recID, payload.highlight)
        if (response.status == 200) {
            commit('SET_HIGHLIGHTS', response.data.data)
        }
    },
    UPDATE_HIGHLIGHT: async ({commit}, payload) => {

        const index = state.highlights.findIndex((highlight) => {
            return highlight.HighlightID == payload.highID
        })

        const response = await requests.updateHighlight(payload.params.collID, payload.params.recID, index ,payload.highlight)
        if (response.status == 200) {
            commit('SET_HIGHLIGHTS', response.data.data)
        }
    },
    DELETE_HIGHLIGHT: async ({commit}, payload) => {

        const index = state.highlights.findIndex((highlight) => {
            return highlight.HighlightID == payload.highID
        })

        const response = await requests.deleteHighlight(payload.params.collID, payload.params.recID, index)
        if (response.status == 200) {
            commit('SET_HIGHLIGHTS', response.data.data)
        }
    },
    UPDATE_TRANSCRIPT: ({commit}, transcript) => {

        commit('SET_TRANSCRIPT', transcript)

    },
    SPLIT_QUOTE: ({commit}, update) => {
        commit("SPLIT_QUOTE", update)
    },
    SET_TRANSCRIPT: async ({commit}, payload) => {

        const response = await requests.getTranscript(payload.collID ,payload.recID)

        if (response && response.status == 200) {

            if (response.data.data.Transcript.length > 0) {
                commit('SET_TRANSCRIPT', response.data.data.Transcript)
                commit('RENAME_SPEAKERS')

            } else {
                return
            }
        }
    },
    UPDATE_TRANSCRIPT_QUOTE: ({commit}, payload) => {
        commit('UPDATE_TRANSCRIPT_QUOTE', payload)
    },
    CLEAR_CURR_RECORDING: async ({commit}) => {
        commit('DELETE_RECORDING_DETAILS')
    },
    RENAME_SPEAKERS: ({commit}) => {
        commit('RENAME_SPEAKERS')
    },
    SET_REC_PROCESS_STATUS: ({commit}, status) => {
        commit('SET_REC_PROCESS_STATUS', status)
    },
    SET_HL_HOVER: ({commit}, highlight) => {
        commit('SET_HL_HOVER', highlight)
    },
    SET_HL_PLAYING: ({commit}, highlight) => {
        commit('SET_HL_PLAYING', highlight)
    },
    SET_PLYR_STATE: ({commit}, plyrState) => {
        commit('SET_PLYR_STATE', plyrState)
    },
    UPDATE_REC_FLAGS: ({commit}, flags) => {
        commit('SET_CURRENT_FLAGS', flags)
    }
}


  export default {
    state,
    getters,
    mutations,
    actions
  }