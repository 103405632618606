import requests from "../requests"

const state = {
    recordingNotes: []
}

const getters = {
    REC_NOTES: state => {
        return state.recordingNotes
    }
}

const mutations = {
    SET_REC_NOTES: (state, notes) => {
        state.recordingNotes = notes
    },
    ADD_NOTE: (state, note) => {
        state.recordingNotes.push(note)
    },
    UPDATE_NOTE: (state, newNote) => {

        const index = state.recordingNotes.findIndex((note) => {
            return note.ID == newNote.ID
        })

        if (index > -1) {
            state.recordingNotes.splice(index, 1)
            
            // really hacky way to get around the fact
            // that vue doesn't update on change if the :key remains the same
            // and qw can't vm.$set from Vuex. 
            setTimeout(() => {
                state.recordingNotes.splice(index, 0 , newNote )
            }, 100)

        }

    },
    DELETE_NOTE: (state, noteID) => {

        const index = state.recordingNotes.findIndex((note) => {
            return note.ID == noteID
        })

        if (index > -1) {
            state.recordingNotes.splice(index, 1)
        }

     }
}

const actions = {
    GET_NOTES_ID: async ({commit}, payload) => {

        const response = await requests.getNotesID(payload)
        if (response.status == 200) { 
            commit('SET_RECORDING_DETAILS', response.data.data)
        }
    },
    GET_NOTES: async ({commit}, payload) => {

        const response = await requests.getNotes(payload)
        if (response.status == 200) { 
            commit('SET_REC_NOTES', response.data.data.Notes)
        }
    },
    ADD_NOTE: async ({commit}, payload) => {

        const response = await requests.addNote(payload)
        if (response.status == 200) {
            commit('ADD_NOTE', response.data.data)
        }
    },
    UPDATE_NOTE: async ({commit}, payload) => {

        const response = await requests.updateNote(payload)
        if (response.status == 200) {
            commit('UPDATE_NOTE', response.data.data)
        }

    },
    DELETE_NOTE: async ({commit}, payload) => {

        const response = await requests.deleteNote(payload)
        if (response.status == 200) {
            commit('DELETE_NOTE', payload.noteID)
        }

    }
}

  export default {
    state,
    getters,
    mutations,
    actions
  }