
const state = {
    fatalError: false, // app level error - e.g. User Info not returned from server and will need to refresh
}

const getters = {
    FATAL_ERR: state => {
        return state.fatalError
    }

}

const mutations = {
    SET_FATAL: (state, error)  => {
        state.fatalError = error
    }

}

const actions = {
    THROW_ERR: ({commit}, errName) => {

        switch (errName) {

            case 'fatalError' : 
                commit('SET_FATAL', true)
                break

        }

    }
}


  export default {
    state,
    getters,
    mutations,
    actions
  }