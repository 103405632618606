
const state = {
    showMenu: null,
    bgGradientHeight: 300,
    browser: '',
    isMobile: false,
}

const getters = {
    MENU_STATUS : state => {
        return state.showMenu
    },
    BG_HEIGHT: state => {
        return state.bgGradientHeight
    },
    BROWSER: state => {
        return state.browser
    },
    IS_MOBILE: state => {
        return state.isMobile
    }
}

const mutations = {
    TOGGLE_MENU: (state) => {
        // console.log(state.showMenu)
        state.showMenu = !state.showMenu
    },
    CHANGE_HEIGHT: (state, height) => {
        state.bgGradientHeight = height
    },
    SET_BROWSER: (state, browser) => {
        state.browser = browser
    },
    SET_MOBILE: (state, bool) => {
        state.isMobile = bool
    }
}

const actions = {
    TOGGLE_MENU : ({commit}) => {
       commit('TOGGLE_MENU');
    },
    CHANGE_HEIGHT : ({commit}, height) => {
        commit('CHANGE_HEIGHT', height);
     },
     SET_BROWSER : ({commit}, browser) => {
         commit('SET_BROWSER', browser)
     },
     SET_MOBILE: ({commit}, bool) => {
         commit('SET_MOBILE', bool)
     }
}

  export default {
    state,
    getters,
    mutations,
    actions
  }