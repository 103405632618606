/* eslint-disable */
import store from './store'
import { Auth } from 'aws-amplify';
import Vue from 'vue'
// import { AmplifyEventBus } from 'aws-amplify-vue';
import router from './router'
import requests from './requests'

export async function signOut () {

    return new Promise(async (resolve, reject) => {
        await Auth.signOut()
        .then(()=> resolve())
        .catch((err) => {
            // console.log(err)
            reject(err)

        })
    })

}

export async function isSignedIn() {
    return new Promise(async (resolve, reject) => {
      await Auth.currentAuthenticatedUser()
      .then(() => {
        resolve();
      })
      .catch((err) => {
          reject(err);
      })
    });
}

export async function keepAlive () {

        await Auth.currentSession()
        .then( data => {
            // console.log('checking if alive', data)
            setAuthHeader(data)
            requests.setCookies()
        })
        .catch(err => {

            //console.log(err)

            if (router.currentRoute.name != 'login') {
                router.push({name: 'login'})
            }

        })
}

export function getSession () {

        Auth.currentSession()
        .then(data => {

            setAuthHeader(data)
            setUserDetails()
            requests.setCookies()
            store.dispatch('MY_COLLECTIONS')

        })
        .catch(err => {

            // console.log(err)

            if (router.currentRoute.name != 'login') {
                router.push({name: 'login'})
            }
        })
}

export function setAuthHeader (data) {

        let accessToken = data.getAccessToken()
        let jwt = accessToken.getJwtToken()
        Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${jwt}`
}

export function setUserDetails () {

        // Auth.currentUserInfo()
        // .then(data => {
        //     console.log("the user stuff", data)
        //     store.dispatch('SET_COGNITO_INFO', data.attributes)
        //     store.dispatch('SET_USER_DETAILS')
        // })

        // .catch(err => {})

        Auth.currentSession()
        .then(data => {
            store.dispatch('SET_COGNITO_INFO', data.idToken.payload)
            store.dispatch('SET_USER_DETAILS')
            // console.log("the session stuff", data)
            // console.log(data)
        })

        // Auth.currentAuthenticatedUser()
        // .then(data => {
        //     console.log("the user stuff", data)
        // })
}
