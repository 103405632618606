<template>

<v-app>

  <div class="background-feature"> </div>
  <!-- <app-menu v-if="$route.name != 'login'"></app-menu>
  <app-header  v-if="$route.name != 'login'"></app-header> -->

  <v-content>

    <div class="content">
      <div class="msg">
      Saylient is no longer in service
    </div>
    </div>

<!-- 

    <app-error v-if="fatalError"></app-error>

    <app-query-account
    v-if="showQuery"
    @close="showQuery = false"
    ></app-query-account>

    <app-terms
    v-if="(!termsAccepted && userDetails)"
    >
    </app-terms>

    <app-welcome v-if="showWelcome"
    @closeWelcome="showWelcome = false"
    >
    </app-welcome>

    <div>
      <router-view :key="$route.fullPath"></router-view>
    </div> -->

  </v-content>
<!-- 
      <notifications group="recUpdate"/>

      <v-snackbar
      :top="true"
      v-model="errorSnackbar">
      Access Denied: Please upgrade your account
      <v-btn
        color="blue"

        @click="errorSnackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-snackbar
      :top="true"
      :color="snackbarMessageColor"
      v-model="messageSnackbar">
      {{snackbarMessage}}
      <v-btn

        @click="messageSnackbar = false"
      >
        Ok
      </v-btn>
    </v-snackbar>

    <app-my-code v-if="showCodeModal" @close="showCodeModal = false" :userDetails="userDetails"></app-my-code>
    <app-redeem-code v-if="showRedeemModal" @close="showRedeemModal = false"></app-redeem-code>

    <div id="closing-msg" v-bind:class="[showClosingMsg ? 'open' : 'closed']">
      <div class="closing-msg-text">{{$t('auth.closingMessage')}}</div>
      <div class="closing-msg-close" @click="showClosingMsg =! showClosingMsg">
        <div class="material-icons">{{showClosingMsg ? 'clear' : 'warning'}}</div>
      </div>
    </div> -->

</v-app>

</template>

<script>
// import Header from './components/Header'
// import Menu from './components/Menu'
// import QueryAccount from './components/QueryAccountMD'
// import TermsMD from './views/TermsMD'
// import WelcomeMD from './components/WelcomeMD'
// import EventBus from './eventBus'
// import ErrorMD from './components/ErrorMD'
// import MyCodeMD from './components/MyCodeMD'
// import RedeemCodeMD from './components/RedeemCodeMD'
// import {getSession, keepAlive} from './auth.js'

export default {
  data () {
    return {
      errorSnackbar: false,
      messageSnackbar: false,
      showQuery: false,
      showWelcome: false,
      showCodeModal: false,
      showRedeemModal: false,
      keepAliveTimer: null,
      snackbarMessage: "",
      snackbarMessageColor: 'info',
      showClosingMsg: true,
    }
  },
  components: {
    // appHeader : Header,
    // appMenu: Menu,
    // appQueryAccount: QueryAccount,
    // appTerms: TermsMD,
    // appError: ErrorMD,
    // appWelcome: WelcomeMD,
    // appMyCode: MyCodeMD,
    // appRedeemCode: RedeemCodeMD
  },
  created () {

    // this.$store.dispatch('SET_WIDTH', window.innerWidth)

    // // show access denied snackbar if user isn't able use feature
    // EventBus.$on('ACCESS_DENIED', () => {
    //     this.errorSnackbar = true
    // })

    // // show message  in snackbar to inform user of mistakes/changes needed
    // EventBus.$on('SHOW_MESSAGE', (message, color) => {

    //     if (color) {this.snackbarMessageColor = color}
    //     this.snackbarMessage = message
    //     this.messageSnackbar = true

    //     setTimeout(() => {
    //       this.messageSnackbar = false
    //       this.snackbarMessageColor = 'info'
    //     }, 5000)

    // })

    // // show access denied snackbar if user isn't able use feature
    // EventBus.$on('SHOW_WELCOME', () => {

    //   setTimeout(() => {
    //       this.showWelcome = true
    //   }, 2000)

    // })

    // // show access denied snackbar if user isn't able use feature
    // EventBus.$on('SHOW_MY_CODE', () => {
    //   this.showCodeModal = true
    // })

    // EventBus.$on('SHOW_REDEEM_CODE', () => {
    //   this.showRedeemModal = true
    // })

    // // show access denied snackbar if user isn't able use feature
    // EventBus.$on('SHOW_QUERY', () => {
    //     this.showQuery = true
    // })

    // var self = this

    // // refresh customer details when account is updated
    // // wait 2 seconds as chargebee/ses sometime take a while to update downstream
    // EventBus.$on('ACCOUNT_CHANGE', () => {
    //   setTimeout( function() {
    //     self.$store.dispatch('SET_USER_DETAILS')
    //   }, 1500)
    // })

  },
  mounted () {

  //   getSession()
  //   this.checkBrowser()

  //   setTimeout(() => {
  //     getSession()
  //   }, 1000)

  //   this.$nextTick(() => {
  //     window.addEventListener('resize', () => {
  //       this.$store.dispatch('SET_WIDTH', window.innerWidth)
  //     });
  //   })

  //   this.keepAliveTimer = setInterval(() => {
  //     keepAlive()
  //   }, 60000)
  // },
  // computed: {
  //   bgGradientHeight: function () {

  //     if (this.$route.name == 'login') {
  //       return 1400
  //     } else {
  //       return 0
  //     }
  //     // return this.$store.getters.BG_HEIGHT
  //   },
  //   expiryTime: function () {
  //     return this.$store.getters.JWT_EXPIRY
  //   },
  //   termsAccepted: function () {
  //     return this.$store.getters.TERMS_ACCEPTED
  //   },
  //   currentRoute: function () {
  //     return this.$router.currentRoute
  //   },
  //   userDetails: function () {
  //     return this.$store.getters.USER_DETAILS
  //   },
  //   fatalError: function () {
  //     return this.$store.getters.FATAL_ERR
  //   },
  //   isMobile: function () {
  //     return this.$store.getters.IS_MOBILE
  //   },
  //   recProcessStatus () {
  //   return this.$store.getters.REC_PROCESS_STATUS
  //   }
  },
  watch: {
  //   recProcessStatus : function () {
  //     if (this.recProcessStatus == 'uploading') {
  //         window.addEventListener("beforeunload", function (e) {
  //         // Cancel the event
  //         e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  //         // Chrome requires returnValue to be set
  //         e.returnValue = '';})


  //     } else {
  //         window.addEventListener("beforeunload", function (e) {
  //         // Cancel the event
  //         e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  //         // Chrome requires returnValue to be set
  //         e.returnValue = '';})
  //     }
  //   }
  },
  methods: {

    // uploadWarningMessage: function (e) {
    //             var confirmationMessage = 'Your file may still be uploading. '
    //                       + 'If you leave before this finishes, your recording will be lost.';

    //       (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    //       return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    // },
    // checkBrowser: function () {

    //   // console.log(navigator.userAgent)

    //   let isMobile = !!navigator.userAgent.match(/iphone|android|blackberry/ig) || false
    //   // console.log(isMobile)
    //   this.$store.dispatch('SET_MOBILE', isMobile)

    //   // console.log(this.$browserDetect.meta.name)
    //   this.$store.dispatch('SET_BROWSER', this.$browserDetect.meta.name)

    //   let ua = navigator.userAgent;
    //   let matches = ua.match(/^.*(iPhone|iPad).*(OS\s[0-9]).*(CriOS|Version)\/[.0-9]*\sMobile.*$/i);

    //     if (matches && matches[3] === 'CriOS') {
    //       // console.log("ChromeIOS")
    //       this.$store.dispatch('SET_BROWSER', "ChromeIOS")
    //             this.$store.dispatch('SET_MOBILE', true)

    //     }

    // }
  }
}

</script>

<style lang="scss">
@import "@/styles/main";


.content {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  // border: 1px solid blue;
  height: 100%;

}

.msg {
  border: 1px solid #c6c6c6;
  background-color: white;
  width: 450px;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
  padding: 2rem;
}


#closing-msg {
  min-height: 40px;
  width: 100%;
  position:fixed;
  bottom: 0;
  left: 0;
  background-color: rgb(117, 0, 0);
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

#closing-msg.closed {
  background: rgba(117, 0, 0, 0);
}

.closing-msg-text {
  color: white;
  max-width: 800px;
}

.closed .closing-msg-text {
   height: 0px;
   opacity: 0;
}

.closed .closing-msg-close {
   color: rgb(177, 0, 0);
}

.closing-msg-close {
  // border: 1px solid white;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
}


/* TEMPORARY */
#app .title-bar {
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
}

#app .material-icons {
  font-feature-settings: 'liga';
  text-rendering: optimizeLegibility;
  font-family: 'Material Icons' !important;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #34343a;
  background: white;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgb(245, 245, 245)
}

a {
  color: #34343a;
  text-decoration: none;
}

// .highlight-card, .coll-grid-card, .rec-card, .plan-card, .details-card, .addon-card {
//   border-radius: 8px !important;
// }

#app .card-shadow {
    -webkit-box-shadow: 1px 1px 3px 2px rgba(0,0,0,.20);
    box-shadow: 1px 1px 3px 2px rgba(0,0,0,.20);
}

#app .card-dash {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 2px gray dashed;
}

#app .no-touch .card-hover:hover {
    -webkit-box-shadow: 1px 2px 10px 2px rgba(0,0,0,.30);
    box-shadow: 1px 2px 10px 2px rgba(0,0,0,.30);
    cursor: pointer;
}

#app-container {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    margin: auto;
    padding: 24px;
    width: 100%;
}

.content-tabs {
  // margin-top: 1em;
  border-bottom: 1px solid #4a4a4a30;
  margin-bottom: 2em;
  width: 100%;
}

.view-content {
  padding: 2em 0;
}

.content-tabs .tabs-active{
  color: #0BAAFF;
  opacity: 1 !important;
}

.std-text {
  color: rgba(52, 52, 52, 0.86);
}

.title-font {
  font-family: 'Cabin', sans-serif;
  font-weight: 700;
}

h1, h2, h3, h4 {
  /* font-family: 'Martel', serif; */
  /* font-weight: 700; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app .title-bar {
    position:relative;
    padding: 2em 0 0 0;
    display: flex;
}

.page-title {
    flex-grow: 2;
    text-align: left;
    justify-content: flex-start;
}

#app .main-page-title {
  font-size: 2.5em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0.2em;

}

#app .main-page-description {
  width: 70%;
}

#app .page-title {
    margin-left: 4em;
}

@media (min-width: 1px) and (max-width: 900px) {

  #app .main-page-title, #app .page-title p {
    text-align: center;
    margin: 0 auto;
  }

  #app .scale-text {
  font-size: calc(1em + 2vw)
  }


#app .page-title {
    margin-left: 0;
}

}

.title-subhead {
  margin-top: 12px;
  font-weight: 400;
  color: #34343a;
}

.form-subheading {
  color: #005887;
  margin-top: 24px;
  font-size: 18px;
}

.form-subtitle {
  color: #00588785;
  margin-bottom: 24px;
  font-size: 12px;
}

.background-feature {
    z-index: 0;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 300px;
    background-image: linear-gradient(23deg, transparent 50%, #2AFD8B50);
    background-color:#34B6FC40;
    box-shadow: 1px 0px 2px rgba(115, 133, 142, 0.5);
    transition: all 0.5s ease-out;
    height: 100%;
}

.view-content {
  padding:  0;
}


#app .v-tabs__item {
  font-weight: 600;
}


.grid-card .action-row {
    height: 40px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.grid-card .action-row button {
  margin: 6px 1px;
}

/* .card-grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  padding: 0 3em;
} */

.card-grid {
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  padding: 0 3em;
}

.collection-list {
  display: flex;
  flex-flow: column;
  width: 80%;
  margin: 0 auto;
  border-radius: 4px;
  /* overflow: hidden; */
  border: 1px solid #4a4a4a20;
}

#app .grid-card {
  // margin: 0.7rem;
  overflow: hidden;
  transition: all 0.3s ease-out;
}

@media (min-width: 1px) and (max-width: 1000px) {

  // #app .grid-card {
  //   margin: 0.5rem
  // }

  .card-grid {
    padding: 0 0.3em;
    margin: 0 auto;
    justify-content: center;
  }

}

.card-shadow, .small-card {
  background: #fff

}

.grid-card {
  padding: 2em 1.5em;
}

#app .add-card {
  color:rgb(120, 120, 120);
}

#recordings-grid .add-card {
  color:rgb(120, 120, 120);
  height: 2em;
  justify-content: center;
  cursor: pointer;
  opacity: 0.5;
}

.no-touch #recordings-grid .add-card:hover{
  opacity: 1;
}

.add-card .large-icon {
    font-size: 52px;
    margin: 50px 50px 80px 50px;
}

.small-card {
    border: 1px solid rgb(220, 220, 220);
    border-radius: 8px;
}

.medium-icon {
  font-size: 1em;
}

.title-text {
  text-align: left;
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}

.title-colour {
  color: $title-color;
}

.icon-colour {
color: #424242
}

.title-gray {
  color: gray;
}

.accent-blue {
  color: #0BAAFF;
}

.accent-background {
  background-color: #0BAAFF;
}

.warning-colour {
  color:rgba(255, 0, 0, .55);
}

#app .add-card-title {
  text-align: left;
  font-size: 18px;
  width: 70%;
  margin: 0 20px 12px 20px;
}

#app .grid-desc {
  text-align: left;
  color: #424242;
  margin: 0 20px;
  flex-grow: 2;
  max-height: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}

.grid-desc::after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2.5em;
  background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 50%);
}

#app .grid-card-bottom-row {
  display: flex;
  justify-content: flex-end;
}

#app .grid-card-bottom-row > div {
  display: flex;
  width: 40%;
  justify-content: center;
  margin: 0 auto;
}

.card-icon {
  margin-right: 5px;
  color: #424242;
}

#app .cta-accent * {
    color: #0BAAFF80;
}

.light-grey-icon {
  color:rgba(0, 0, 0, .75);
}

.grey-text {
  color:rgb(140, 162, 172);
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}

.description {
  margin: 48px auto 8px auto;
  width: 45%;
  font-size: 12px;
  // color:rgb(69, 80, 85);
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
}

.description i {
  font-size: 24px;
  color:rgba(115, 133, 142, 0.5);
  // margin-bottom: 16px;
  margin: 16px auto;
}

.description-icon {
  font-size: 48px;
}

.description h3 {
  font-size: 18px;
  color:rgba(115, 133, 142, 0.5);
  margin-bottom: 18px;
}

.description .big-icon {
  font-size: 54px;
}

.description h4 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 32px;
  color:rgba(115, 133, 142, 0.5);
}

.description-heading {
  margin: 8px auto ;
}

.green-text {
  color: green;
}

.success-icon {
  color: #00BB57;
  font-size: 3em;
  font-weight: 700;
}

.success-text {
  color: #00BB57;
  font-weight: 700;
}

.error-text {
  color:rgba(255, 0, 0, .55);
  font-weight: bold;
}

.error-bg{
  background:rgba(255, 0, 0, .55);
}

.no-highlight {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}


.no-highlight * {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.bold {
  font-weight: bold;
}

.saylient-btn {
  cursor: pointer;
  transition: all 0.3s;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */

}

.no-touch .saylient-btn:hover {
  text-shadow: 1px 1px 10px #ffffff;
}

.search-box {
  border:2px solid rgba(0, 0, 0, 0.55) ;
  border-radius: 4px;
  display: flex;
  margin-bottom: 2em;
  position: relative;
  flex-grow: 2;
}


.search-box input {
  border: none;
  flex-grow: 2;
  padding: 0.5em 1em;
  font-size: 1em;
  font-weight: 700;
  /* height: 1.5em; */
  width: 100%;
  min-height: 42px;
}

.search-box input::placeholder{
  opacity: 0.5;
}


.search-box .material-icons {
  margin: 0.3em;
  border-radius: 100%;
  position: absolute;
  right: 1px;
  top: 1px;
}

.tag-chip {
    font-weight: 700;
    padding: 6px 6px;
    border-radius: 4px;
    margin: 5px;
}

.flag-tootip {
      text-align: left;
      padding: 1em;
      margin: 1em;
      color: red;
}

/* VIDEO CONTROLS */

// #saylient-controls {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
// }


// .progress-control {
//     display: flex;
//     flex-direction: row;
//     flex-grow: 2;
//     justify-content: center;
// }

// #player-bar {
//     width: 70%;
//     flex-grow: 3;
//     margin: 0 20px 0 5px;
// }

// .video-box {
//     box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
// }

// .relative {
//   position: relative;
// }

.loading-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    font-weight: 700;
    background-color: rgba(255,255,255, 0.88);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: rgba(0,0,0, 0.55);
    transition: all 2s ease-out;
}

// #highlight-sider {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
// }

// #app .plyr__tooltip {
//     margin: 0 0 17px 0;
//     padding: 5px 7.5px 8px 7.5px;
//     background: rgb(100, 255, 218);
// }

// #app .plyr__tooltip::before {
//     border-color: rgb(100, 255, 218) transparent transparent transparent
// }

// .plyr--full-ui input[type=range] {
//     color: rgb(174, 175, 176);
// }

// .plyr__progress__buffer{
//     opacity: 0;
// }

// .plyr--video {
//     overflow: visible;
// }

// .fadehighlights {
//     opacity: 0.25;
// }

// .video-box .audioclass {
//     background:#34B6FC40;
//     height: 80px;
//     overflow: hidden;
// }



// .plyr--audio {
//   background: #000;
// }

// #app .plyr--audio .plyr__controls {
//    background: #34B6FC30;
//    padding-top: 30px;   /* height: 80px; */
// }

// #app .plyr--audio .plyr__control svg {
//   fill: white;
// }

// .plyr--audio .plyr__time {
//   color: white;

// }


// @media (min-width: 1px) and (max-width: 600px) {

//     .video-box {
//         overflow:hidden;
//     }

//     #saylient-controls .plyr__controls  {
//         padding: 0;
//     }

    /* #saylient-controls .plyr__time {
        font-size: 10px;
    } */

    /* #saylient-controls > div {
        margin: 0 8px;
    } */

    /* #saylient-controls .small-m, #saylient-controls .small-m use { */
        /* transform: scale(0.9); */
        /* padding: 1px; */
    /* } */

    // #app #saylient-controls .hide-m {
    //   width: 0;
    //   height: 0;
    //   display: none;
    //   visibility: hidden;
    // }

    // #saylient-controls .plyr__controls_left {
    //     margin: 0;
    // }

    // .collection-form {
    //   display: flex;
    //   flex-flow: column;
    //   justify-content: space-between;
    //   flex-grow: 2;
    // }


// @media (min-width: 1px) and (max-width: 350px) {

//       #saylient-controls > div {
//           margin: 0 2px;
//       }

//       #saylient-controls .small-m, #saylient-controls .small-m use {
//           /* transform: scale(0.9); */
//           padding: 0;
//       }
//   }

// }
/*
.mobile-fab {
  opacity: 0;
  visibility: hidden;
  bottom: 1em;
  left: 1em;
  position: fixed;
  z-index: 100;
  border-radius: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-box-shadow: 0 0 15px 3px rgba(0,0,0,.30);
  box-shadow: 0 0 15px 3px rgba(0,0,0,.30);
  width: 11vh;
  height: 11vh;
  /* background-color:rgb(227, 246, 255);
  background-color: #424242;
  transition: all 0.3s ease-out;
  padding: 0.6em;
}
*/

/* .mobile-fab:hover {
  width: 11.2vh;
  height:  11.2vh;
}

.mobile-fab--icon {
  margin: 0px;
  font-weight: 400;
  font-size: 3em;
  color: white;
}

.mobile-fab--label {
  margin-top: -0.5em;
  margin-bottom: 0;
  font-weight: 400;
  color: white
}  */

.bottom-menu {
  /* background-color: #4a4a4a; */
  background-color: black;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1.2em 1em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 -3px 5px 1px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  height: 0;

}

.bottom-menu button, .bottom-menu .material-icons {
  font-weight: 700;
}

.bottom-menu .material-icons {
  font-size: 2em;
  margin-right: 0.3em;
}

@media (min-width: 1px) and (max-width: $breakpoint-medium) {

  // .mobile-fab {
  //   opacity: 1;
  //   visibility: visible;
  // }

  .bottom-menu {
    opacity: 1;
    visibility: visible;
    height: auto;
  }

  // #app .collection-view .add-card {
  //   height: 0;
  //   width: 0;
  //   position: absolute;
  //   left: 200em;
  // }

}




</style>
