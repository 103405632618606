<template>

<div id="auth-box">

    <div class="auth-box--top">
        <img class="logo"
        @click="incrementDevMode()"
        src="../../public/logo.svg"
        alt="saylient.io"
        />
        <h2 class='auth-box--title'>Saylient</h2>
    </div>

    <div id="auth-form" class="card-shadow">

        <div>
            Saylient is no longer in service.
        </div>

        
            <!-- <app-sign-in v-show="display == 'signin'" :devMode="devMode"></app-sign-in>
            <app-forgot-password v-show="display == 'forgotpassword'"></app-forgot-password>
            <app-sign-up :confirmCode="confirmCode" v-show="display == 'signup'" :devMode="devMode"></app-sign-up>

            <language-selector class="lang-selector" :menu="false"></language-selector> -->

    </div>


</div>

</template>

<script>
// import SignIn from './../components/login/SignIn'
// import ForgotPassword from './../components/login/ForgotPassword'
// import SignUp from './../components/login/SignUp'
import EventBus from './../eventBus'
// import LanguageSelector from './../components/LanguageSelector'

export default {
    components: {
        // appSignIn: SignIn,
        // appForgotPassword: ForgotPassword,
        // appSignUp: SignUp,
        // LanguageSelector
    },
    data () {
        return {
        display: "signin",
        confirmCode: false,
        windowWidth: 900 ,
        devCounter: 0,
        }
    },
    mounted () {

        if (this.$route.query.signup) {
            this.display = 'signup'
        }

        EventBus.$on('AUTH_RESET_PWD', () => {
            this.display = 'forgotpassword'
        })

        EventBus.$on('AUTH_SIGN_IN', () => {
            this.display = 'signin'
        })

        EventBus.$on('AUTH_SIGN_UP', () => {
            this.display = 'signup'
        })

        EventBus.$on('AUTH_CONFIRM_CODE', () => {
            this.confirmCode = true
            this.display = 'signup'

        })

        this.$store.dispatch('CHANGE_HEIGHT', window.innerHeight + 100)

    },
    watch : {
        userDetails: function () {
            if (this.userDetails) {
                this.$router.push({name : "home"})
            }
        }
    },
    computed: {
        userDetails() {
            return this.$store.getters.USER_DETAILS
        },
        devMode () {
            return this.$store.getters.DEV_MODE
        }
    },
    methods : {
        incrementDevMode: function () {

            this.devCounter ++

            if (this.devCounter > 12) {
                this.$store.dispatch('TOGGLE_DEV_MODE', true)
            }
}
    }

}
</script>


<style scoped>


</style>

<style lang="scss">

@import "@/styles/abstracts/_variables";

#auth-box {
    margin: 6em auto;
}

.auth-box--top {
    position: absolute;
    top: 2em;
    left: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    height: 50px;
    width: 50px;
}

.auth-box--title {
    font-size: 2em;
    margin-left: 0.2em;
}

#auth-form {
    background-color: white;
    margin: 1em auto;
    padding: 2em 3em;
    max-width: 500px;
    border-radius: 4px;
    transition: all 0.5s;
    position: relative;
}

@media (min-width: 1px) and (max-width: 700px) {

#auth-box {
    margin: 6em auto;
    padding: 0.2em 1em;
    width: 100%;
}

#auth-form {
    padding: 2em 2em;
}

#auth-form li {
    font-size: calc(0.4em + 1vw);
}

}


#auth-form .auth--header {
    font-size: 1.2em;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    margin-bottom: 1.5em;
}

#auth-form input {
    border: 1px solid #44444440;
    height: 3em;
    margin: 0.5em auto;
    width: 100%;
    padding: 14px;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
}

#auth-form input:focus {
    border: 2px solid #0084ff70;
    padding: 13px;
}

#auth-form .input-section {
    margin: 1em auto;
    display: flex;
    flex-flow: column;
}

#auth-form .input-label {
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    text-align: left;
    width: 100%;
    font-weight: 700;
}

#auth-form .form-footer {
    width: 100%;
    text-align: left;
    margin: 0.5em 0;
}

#auth-form .auth--button{
    margin: 0 0.2em;
    background-color: #0BAAFF;
    font-weight: 700;
    color: white;
    padding: 0 2em;
    border-radius: 4px;
    flex-grow: 2;
}

#auth-form .social{
    margin: 0.4em 0.2em;
    background-color: #0BAAFF;
    font-weight: 700;
    color: white;
    padding: 0 2em;
    border-radius: 4px;
    flex-grow: 2;
    display: flex;
    align-items: center;
}

#auth-form .google-login {
    background: white;
    color: $text-color;
    margin-top: 0.5em
}

.social-logo {
    height: 1.6em;
    width: 1.6em;
    margin-right: 1em;
    background: white;
}

#auth-form .auth--alt-button{
    /* margin: 1em 1em 0 1em; */
        margin: 0 0.2em;

    font-weight: 700;
    padding: 0 2em;
    border-radius: 8px;
    flex-grow: 2;
}

#auth-form .auth--err-msg{
    height: 2em;
    margin-top: -1em;
}

#auth-form .loading {
    background-color: rgba(255,255,255,0.55);
    width: 90%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

#auth-form .login-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    /* border: 1px solid red; */
}

.login-options {
    display: flex;
    flex-flow: column;
}

.login-options .social {
    margin: 4em auto;
    padding: 3em;
    /* border: 1px solid red; */

}

</style>

<style scoped>
.lang-selector {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 2em;
  right: 1em;

}
</style>