import requests from './../requests'

const state = {
    collEditing: {},
    collections: [],
    recordings: [],
    highlightsAll: [],
    collectionDetails: {},
    vocabulary: [],
}

const getters = {
    COLLECTIONS : state => {
        return state.collections
    },
    GET_COLLECTION : (state) => {
        return state.collectionDetails
    },
    GET_RECORDINGS : (state) => {
        return state.recordings
    },
    GET_HL_SUMMARY: (state) => {
        return state.highlightsAll
    },
    GET_UNIQUE_TAGS: (state) => {
        let tags = []
        state.highlightsAll.forEach(highlight => {
            if (highlight.Tags) {
                highlight.Tags.forEach(tag => {
                    tags.push(tag)
                })
            }
        })
        const distinctTags = [... new Set(tags)] 
        return distinctTags
    },
    GET_RECORDING_NAMES: (state) => {
        
        if (state.recordings == null) {
            return null
        } else {
            let names = []
            state.recordings.forEach(recording => {
                    names.push(recording.Name)
            })
            const distinctNames = [... new Set(names)] 
            return distinctNames
        }
        },
    GET_NEWCOLL: (state) => {
        return state.newCollID
    },
    GET_VOCABULARY: (state) => {
        return state.vocabulary
    },
    GET_VOCAB_COUNT: (state) => {
        return state.vocabulary ? state.vocabulary.length : 0
    },
    GET_COLL_EDITING: (state) => {
        return state.collEditing
    }
}

const mutations = {
    MY_COLLECTIONS: (state, collections) => {

        collections.sort(function (a,b) {
            a = new Date(a.CreatedDate);
            b = new Date(b.CreatedDate);
            return a > b ? -1 : a < b ? 1 : 0;
        })

        state.collections = collections

    },
    ADD_COLLECTION: (state, collection) => {

        if (state.collections) {
            state.collections.push(collection)
        } else {
            state.collections = [collection]
        }
        state.newCollID = collection.CollectionID
    },
    DELETE_COLLECTION: (state, id) => {
        let index = state.collections.findIndex(x => x.CollectionID == id )
        state.collections.splice(index, 1)
    },
    SET_RECORDINGS: (state, recordings) => {
        state.recordings = recordings
    },
    SET_HIGHLIGHT_SUMMARY: (state, recordings) => {
        // state.highlightsAll = highlightSummary

        let highlightSummary = []

        if (!recordings) {
            return
        }

        recordings.forEach((recording) => {

            //Get the collectionID and recordingID from each Recording
            let collID = recording.CollectionID
            let recID = recording.ItemID 
            let recName = recording.Name
            let mediaType = recording.MediaType

            // Loop through the highlights, add the collection/recording IDs to the object (so we can group)
            // Push each highlight into an array of highlights used in the summary view
            if (recording.Highlights) {

                recording.Highlights.forEach((highlight) => {
                    highlight.CollectionID = collID
                    highlight.RecordingID = recID
                    highlight.RecordingName = recName
                    highlight.MediaType = mediaType
                    highlightSummary.push(highlight)
                })

            }
        })

        state.highlightsAll = highlightSummary

    },
    SET_CURRENT_COLLECTION_DETAILS: (state, collection) => {
        state.collectionDetails = collection
    },
    CLEAR_COLLECTION: (state) => {
        state.collectionDetails = {}
        state.collectionRecordings = []
        state.highlightsAll = []
        state.newCollID = ""
        state.vocabulary = []
    },
    SET_VOCAB: (state, vocab) => {
        state.vocabulary = vocab
    },
    SET_COLL_EDITING: (state, collection) => {
        state.collEditing = collection
    },
    CLEAR_COLL_EDITING: (state) => {
        state.collEditing = {}
    },

}

const actions = {
    SET_COLL_EDITING: ({commit}, collection) => {
        commit('SET_COLL_EDITING', collection)
    },
    CLEAR_COLL_EDITING: ({commit}) => {
        commit('CLEAR_COLL_EDITING')
    },
    MY_COLLECTIONS : async ({commit}) => {

        const response = await requests.getUserCollections()
        if (response.status == 200) {
            commit('MY_COLLECTIONS', response.data.data)
        }

    },
    CREATE_COLLECTION: async ({commit}, collection) => {

        const response = await requests.createCollection(collection)
        if (response.status == 200) {
            commit('ADD_COLLECTION', response.data.data)
        }

    },
    UPDATE_COLLECTION: async ({commit}, collection) => {

        const response = await requests.updateCollection(collection)
        if (response.status == 200) {
            //If update was successful - refresh my collection data
            const response = await requests.getUserCollections()
            if (response.status == 200) {
                commit('MY_COLLECTIONS', response.data.data)
            }
        }

    },
    SET_CURRENT_COLLECTION: async ({commit}, collectionID) => {

        const response = await requests.getCollectionDetails(collectionID)
        if (response.status == 200) {
            commit('SET_CURRENT_COLLECTION_DETAILS', response.data.data)
           
            if (response.data.data.Vocabulary) {
                commit('SET_VOCAB', response.data.data.Vocabulary)
            }
        }

    },
    SET_RECORDINGS: async ({commit}, collectionID) => {
  
        const response = await requests.getCollectionRecordings(collectionID)
        if (response.status == 200) {
            commit('SET_RECORDINGS', response.data.data)
            commit('SET_HIGHLIGHT_SUMMARY', response.data.data)
        }
    },
    GET_VOCAB: async ({commit}, collectionID) => {
  
        const response = await requests.getVocabulary(collectionID)
        if (response.status == 200) {
            commit('SET_VOCAB', response.data.data)
        }
    },
    ADD_VOCAB: async ({commit}, payload) => {
        const response = await requests.createVocabulary(payload.collID, payload.detailsID, payload.term)
        if (response.status == 200) {
            commit('SET_VOCAB', response.data.data)
        }
    },
    DELETE_VOCAB: async ({commit}, payload) => {

        const response = await requests.deleteVocabulary(payload.collID, payload.detailsID, payload.index)
        if (response.status == 200) {
            commit('SET_VOCAB', response.data.data)
        }
    },
    DELETE_COLLECTION: async ({commit}, id) => {

        const response = await requests.deleteCollection(id)
        if (response.status == 200) {
            commit('DELETE_COLLECTION', id)
        }
    },
    CLEAR_COLLECTION: ({commit}) => {
        commit('CLEAR_COLLECTION')
    },
    ADD_VOCABULARY: ({commit}, term) => {
        commit('ADD_VOCABULARY', term)
    }
    
}

  export default {
    state,
    getters,
    mutations,
    actions
  }