// import 'material-icons'
import 'material-icons/iconfont/material-icons.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
// import vuetify from './plugins/vuetify';
import LocalStorage from 'vue-localstorage'
import VueAnalytics from 'vue-analytics'
import axios from 'axios'
import './filters'
import 'vuetify/dist/vuetify.min.css'
import awsconfig from './aws-exports'
import browserDetect from "vue-browser-detect-plugin"
import Ripple from "vue-ripple-directive"
import Notifications from "vue-notification"
// import Vuetify from 'vuetify/lib'

Ripple.color = "rgba(0, 0, 0, 0.15)";
Ripple.zIndex = 55;
Vue.directive("ripples", Ripple);

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

// Vue.directive('click-outside', {
//   bind () {
//       this.event = event => this.vm.$emit(this.expression, event)
//       this.el.addEventListener('click', this.stopProp)
//       document.body.addEventListener('click', this.event)
//   },
//   unbind() {
//     this.el.removeEventListener('click', this.stopProp)
//     document.body.removeEventListener('click', this.event)
//   },

//   stopProp(event) { event.stopPropagation() }
// })

Vue.use(Notifications)

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
});

// New
import Auth from '@aws-amplify/auth'
// import API from '@aws-amplify/api'
import { AmplifyPlugin } from 'aws-amplify-vue'
Auth.configure(awsconfig)
Vue.use(AmplifyPlugin, { Auth })

Vue.use(LocalStorage, {
  name: 'ls',
  bind: true //created computed members from your variable declarations
})

// Vue.use(AmplifyPlugin, { Auth, API })


// OLD
// import Amplify, * as AmplifyModules from 'aws-amplify'
// import { AmplifyPlugin } from 'aws-amplify-vue'
// Amplify.configure(awsconfig)
// Vue.use(AmplifyPlugin, AmplifyModules)



Vue.use(browserDetect)
// Helpers
import colors from 'vuetify/es5/util/colors'
import VTooltip from 'v-tooltip'
import i18n from './i18n'

// for highlight bar tooltips
Vue.use(VTooltip)

Vue.prototype.$http = axios

Vue.use(require('vue-filter-pretty-bytes'))

Vue.use(Vuetify, {
  theme: {
    primary: colors.blue.base, // #E53935
    secondary: colors.blue.lighten4, // #FFCDD2
    accent: colors.indigo.base // #3F51B5
  }
})

const googleID = process.env.VUE_APP_GA_ID

if (googleID) {
  Vue.use(VueAnalytics, {
    id: googleID,
    router,
    trackEvent: true,
    // debug: {
    //   enabled: true, // default value
    //   trace: true, // default value
    //   sendHitTask: true // default value
    // }
  })
}

Vue.config.productionTip = false

new Vue({
  vuetify: new Vuetify(),
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

