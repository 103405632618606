import Vue from "vue"

Vue.filter('durationFormat', function (value) {
  
    let sec_num = parseInt(value, 10); // don't forget the second param
    let hours   = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours > 0) {
      return hours + 'h ' +  minutes + 'm ' + seconds + 's';
    }

    return minutes + 'm' + ' ' + seconds + 's';
  
  })

  Vue.filter('timeFormat', function (value) {
  
    let sec_num = parseInt(value, 10); // don't forget the second param
    let hours   = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);
  
    if (minutes < 10) {minutes = "0"+minutes}
    if (seconds < 10) {seconds = "0"+seconds;}
  
    if (hours   == 0) {
      return minutes+':'+seconds;
    }

    return hours+':'+minutes+':'+seconds;
  
  })

  Vue.filter('noteTimeFormat', function (value) {

    if (value == 0) {
      return '-- : --'
    } 
  
    let sec_num = parseInt(value, 10); // don't forget the second param
    let hours   = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);
  
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
  
    if (hours == 0) {
      return minutes+':'+seconds;
    }
  
    return hours+':'+minutes+':'+seconds;
  
  })
  
  Vue.filter('dateFormat', function (value) {

    if (value) {
      const year = value.substring(2,4)
      const month = value.substring(5,7)
      const day = value.substring(8,10)
  
      let monthText = ""
  
      switch (month) {
        case "01":
          monthText = "Jan"
          break
        case "02":
          monthText = "Feb"
          break
        case "03":
          monthText = "Mar"
          break
        case "04": 
          monthText = "Apr"
          break
        case "05":
          monthText = "May"
          break
        case "06":
          monthText = "Jun"
          break
        case "07":
          monthText = "Jul"
          break
        case "08": 
          monthText = "Aug"
          break
        case "09":
          monthText = "Sep"
          break
        case "10":
          monthText = "Oct"
          break
        case "11":
          monthText = "Nov"
          break
        case "12": 
          monthText = "Dec"
          break
      }
    
      return day + ' ' + monthText + ` ` + year;

    } else {
      return null
    }

  })

  Vue.filter('speakerize', function (value) {
    return (value) ? "Speaker " + (parseInt(value[value.length -1]) + 1) : "Speaker 1"
    }
  )

  Vue.filter('title-shorten', function (value) {

    const textLimit = 27
    return value.length < textLimit ? value : value.slice(0, textLimit - 3) + "..."
    }
  )

  Vue.filter('text-shorten', function (value) {

    const textLimit = 30
    return value.length < textLimit ? value : value.slice(0, textLimit - 3) + "..."
    }
  )

  
  Vue.filter('prettyDuration', function (minutes) {
    
      let mins = Math.ceil(minutes / 60)

      if (mins == 1) {
          return mins + " minut"
      } else if (mins > 1) {
          return mins + " minutes"
      } else {
        return ""
      }

    }
  )