import axios from 'axios'
// import router from './router'
import store from './store'
import analytics from './analytics'
// import EventBus from './eventBus'
// import { getSession } from './auth'

const baseURL = process.env.VUE_APP_BASE_API_URL

// For debugging
// axios.interceptors.request.use(async (request) => {
//     console.log('Request', request)
//     return request
//   })

//   axios.interceptors.response.use(response => {
//     console.log('Response', response)
//     return response
//   })

//   axios.interceptors.response.use(response => {

//     if (response.status == 401) {
//         console.log("401, redirecting")
//         router.push({ path: '/login' })
//     }

//     return response
//   })

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // If user session has expired
    // log them out and send them to login page
    if (error.response.status == 401) {
        // if we get an access denied response from api server - check with Cognito first
        // getSession will redirect to logout page if expired
        // getSession()
        return error.response
    }
  });

export default {
    getJWT: function () {
        // let jwtToken = auth.auth.getSignInUserSession().getAccessToken().jwtToken
        // return jwtToken
    },
    checkTokenExpiry: function () {

        // store.dispatch('SET_JWT_EXPIRY', jwtToken.exp)
        // const nowTime = new Date () / 1000
        // console.log("time left", jwtToken.exp - nowTime)

        // if the token will expire in under 10 minutes then refresh the session
        // this method is called on route changes
        // if (jwtToken.exp - nowTime < 2100 ) {
        //     this.refreshSession()
        // }

    },
    refreshSession: function () {

        // clear local storage of cognito info and get new tokens
        // This is run on a timer to expire
        // auth.auth.clearCachedTokensScopes()
        // auth.auth.refreshSession()
    },


    setUserInfo(){
        // const jwtToken = this.getJWT()

        // console.log(jwtToken)

        // const USERINFO_URL = 'https://'+auth.auth.getAppWebDomain() + '/oauth2/userInfo';
        // const requestData = {
        //     headers: {
        //         'Authorization': 'Bearer '+ jwtToken
        //     }
        // }
        // return axios.get(USERINFO_URL, requestData).then(response => {
        //     Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`
        //     // decode jwt, get group membership out of it and add it to response
        //     // will use eventually for trial and plan mgmt

        //     const token = jwtDecode(jwtToken)

        //     this.checkTokenExpiry(token)

        //     const cognitoInfo = {
        //         cognitoInfo : response.data,
        //         groups : token["cognito:groups"]
        //     }

        //     // console.log(token)

        //     this.setCookies()

        //     if (!store.getters.LOGGED_IN) {
        //         store.dispatch('SET_LOGGED_IN', true)
        //         store.dispatch('SET_COGNITO_INFO', cognitoInfo)
        //         store.dispatch('SET_USER_DETAILS')
        //     }


        //     return
            // return cognitoInfo;
       // });
    },
    setCookies: async () => {
        try {
            //get cookies from auth endpoint - will not set if it's not the same domain
            // i.e. localhost won't save to cookies
            const response = await axios.get(baseURL + 'Auth', {
                withCredentials: true, //correct
            })

            return response

        } catch (error) {
            throw new Error('Unable to get cookies')
        }
    },
    getUserCollections: async () => {
        try {
            const response = await axios.get(baseURL + 'Collection/User')
            return response
        } catch (error) {
            throw new Error('Unable to retrieve Collections')
        }
    },
    createCollection: async (collection) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Collection/',
                data : collection,
            })

            analytics.gaCollection('new')

            return response

        } catch (error) {
            throw new Error('Unable to Create Collection')
        }
    },
    getCollectionDetails: async (collectionID) => {

        try {
            const response = await axios({
                method: 'get',
                url: baseURL + 'Collection/' + collectionID + '/Details',
            })
            return response
        } catch (error) {
            throw new Error('Unable to get Details')
        }
    },
    deleteCollection: async (collectionID) => {

        try {
            const response = await axios({
                method: 'delete',
                url: baseURL + 'Collection/' + collectionID,
            })

            analytics.gaCollection('delete')

            return response
        } catch (error) {
            throw new Error('Unable to delete Collection')
        }
    },
    updateCollection: async (payload) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Collection/' + payload.collID + '/Details',
                data : payload.collection,
            })

            analytics.gaCollection('edit')

            return response
        } catch (error) {
            throw new Error('Unable to Udpate Collection')
        }

    },
    getCollectionRecordings: async (collectionID) => {

        try {
            const response = await axios({
                method: 'get',
                url: baseURL + 'Collection/' + collectionID + '/Recordings',
            })
            return response
        } catch (error) {
            throw new Error('Unable to get Recordings')
        }
    },
    createRecording: async (collectionID, recording) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Recording/' + collectionID,
                data : recording,
            })

            analytics.gaRecording('new', response.data.data)

            return response
        } catch (error) {
            throw new Error('Unable to create Recording')
        }

    },
    updateRecording: async (collectionID, recordingID, recording) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Recording/' + collectionID + '/' + recordingID,
                data : recording,
            })

            analytics.gaRecording('edit')

            return response
        } catch (error) {
            throw new Error('Unable to update Recording')
        }

    },
    updateRecordingFlags: async (collectionID, recordingID, flags) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Recording/' + collectionID + '/' + recordingID + '/Flags',
                data : flags,
            })

            analytics.gaTranscript('flagEdit', 'Add Flag')

            return response

        } catch (error) {
            throw new Error('Unable to update Recording')
        }
    },
    deleteRecordingFlag: async (collectionID, recordingID, flag) => {

        try {
            const response = await axios({
                method: 'delete',
                url: baseURL + 'Recording/' + collectionID + '/' + recordingID + '/Flags',
                data : flag,
            })

            analytics.gaTranscript('flagEdit', 'Delete Flag')

            return response

        } catch (error) {
            throw new Error('Unable to update Recording')
        }
    },
    uploadComplete: async (collectionID, recordingID) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Recording/' + collectionID + '/' + recordingID + "/UploadComplete",
            })
            return response
        } catch (error) {
            throw new Error('Unable to update Recording')
        }

    },
    getRecording: async (collectionID, recordingID) => {

        try {
            const response = await axios({
                method: 'get',
                url: baseURL + 'Recording/' + collectionID + '/' + recordingID,
            })
            return response
        } catch (error) {
            throw new Error('Unable to Fetch Recording')
        }
    },
    deleteRecording: async (collectionID, recordingID) => {

        try {
            const response = await axios({
                method: 'delete',
                url: baseURL + 'Recording/' + collectionID + '/' + recordingID,
            })

            analytics.gaRecording('delete')
            return response

        } catch (error) {
            throw new Error('Unable to Delete Recording')
        }
    },
    createHighlight: async (collectionID, recordingID, highlight) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Highlight/' + collectionID + '/' + recordingID,
                data : highlight,
            })

            analytics.gaHighlight('new', highlight)
            return response

        } catch (error) {
            throw new Error('Unable to Create Highlight')
        }
    },
    updateHighlight: async (collectionID, recordingID, index , highlight) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Highlight/' + collectionID + '/' + recordingID + '/' + index,
                data : highlight,
            })

            analytics.gaHighlight('edit')
            return response

        } catch (error) {
            throw new Error('Unable to Update Highlight')
        }
    },
    deleteHighlight: async (collectionID, recordingID, index) => {

        try {
            const response = await axios({
                method: 'delete',
                url: baseURL + 'Highlight/' + collectionID + '/' + recordingID + '/' + index,
            })

            analytics.gaHighlight('delete')
            return response

        } catch (error) {
            throw new Error('Unable to Delete Highlight')
        }
    },
    getVocabulary: async (collectionID) => {

        try {
            const response = await axios({
                method: 'get',
                url: baseURL + 'Collection/' + collectionID + '/Vocabulary',
            })
            return response
        } catch (error) {
            throw new Error('Unable to Fetch Vocabulary')
        }
    },
    createVocabulary: async (collectionID, detailsID, term) => {

        const termObj = {
            term : term
        }

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Collection/' + collectionID + '/' + detailsID + '/Vocabulary',
                data : termObj,

            })
            return response
        } catch (error) {
            throw new Error('Unable to Add Term')
        }
    },
    deleteVocabulary: async (collectionID, detailsID, index) => {

        try {
            const response = await axios({
                method: 'delete',
                url: baseURL + 'Collection/' + collectionID + '/' + detailsID + '/Vocabulary/' + index,
            })
            return response
        } catch (error) {
            throw new Error('Unable to Delete Term')
        }
    },
    getTranscript: async (collectionID, recID) => {

        try {
            const response = await axios({
                method: 'get',
                url: baseURL + 'Transcript/' + collectionID + '/' + recID,
            })
            return response
        } catch (error) {
            throw new Error('Unable to Fetch Transcript')
        }
    },
    editTranscript: async (collID, recID, quote) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Transcript/' + collID + '/' + recID + '/Edit',
                data: quote
            })
            return response
        } catch (error) {
            throw new Error('Unable to Update Transcript')
        }
    },
    splitTranscript: async (collID, recID, quotes) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Transcript/' + collID + '/' + recID + '/Split',
                data: quotes
            })
            return response
        } catch (error) {
            throw new Error('Unable to Update Transcript')
        }
    },
    deleteTranscriptQuote: async (collID, recID, id) => {

        try {
            const response = await axios({
                method: 'delete',
                url: baseURL + 'Transcript/' + collID + '/' + recID + '/' + id,
            })
            return response
        } catch (error) {
            throw new Error('Unable to Update Transcript')
        }

    },
    mergeTranscriptQuote: async (collID, recID, id) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Transcript/' + collID + '/' + recID + '/Merge/' + id,
            })
            return response
        } catch (error) {
            throw new Error('Unable to Update Transcript')
        }

    },
    restoreTranscript: async (collID, recID) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Transcript/' + collID + '/' + recID + '/Restore',
            })
            return response
        } catch (error) {
            throw new Error('Unable to Restore Transcript')
        }

    },
    getTranscriptOLD: async (userID, collectionID, recID) => {

        try {
            const response = await axios({
                method: 'get',
                withCredentials: true, // with credentials means cookies are sent to CloudFront
                // Remove the default authorization header that DynamoDB/API server uses
                transformRequest: [(data, headers) => { delete headers.common.Authorization; return data }],
                url: process.env.VUE_APP_MEDIA_URL + userID + '/' + collectionID + '/' + recID + '/transcript.json'
            })
            return response
        } catch (error) {
            throw new Error('Unable to Fetch Transcript')
        }
    },
    getUserDetails: async () => {
        try {
            const response = await axios.get(baseURL + 'User')
            return response
        } catch (error) {
            store.dispatch('THROW_ERR', 'fatalError')
            throw new Error('Unable to retrieve User Information')
        }
    },
    signUpUser: async (planID) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'User/SignUp/' + planID,
            })

            analytics.gaUser('signup', response.data.data)
            return response

        } catch (error) {
            throw new Error('Unable to sign up user')
        }
    },
    acceptTerms: async () => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'User/AcceptTerms',
            })
            return response

        } catch (error) {
            throw new Error('Unable to accept terms')
        }
    },
    getPortalDetails: async () => {


        try {
            const response = await axios.post(baseURL + 'Portal')
            return response
        } catch (error) {
            throw new Error('Unable to retrieve Portal Information')
        }
    },
    getCheckout: async (payload) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Plan/Checkout',
                data : payload
            })

            // const response = await axios.post(baseURL + 'Checkout')
            return response
        } catch (error) {
            throw new Error('Unable to retrieve Checkout Information')
        }
    },
    getAddonCheckout: async (payload) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Plan/Checkout/AddOn',
                data : payload
            })

            // const response = await axios.post(baseURL + 'Checkout')
            return response
        } catch (error) {
            throw new Error('Unable to retrieve Checkout Information')
        }
    },
    getUserPlan: async (planID) => {
        try {
            const response = await axios.get(baseURL + 'Plan/' + planID)
            return response
        } catch (error) {
            throw new Error('Unable to retrieve Plan Information')
        }
    },
    getPlans: async () => {
        try {
            const response = await axios.get(baseURL + 'Plans')
            return response
        } catch (error) {
            throw new Error('Unable to retrieve plans')
        }
    },
    getAddons: async () => {
        try {
            const response = await axios.get(baseURL + 'Plans/Addons')
            return response
        } catch (error) {
            throw new Error('Unable to retrieve addons')
        }
    },
    requestCancellation: async (accRequest) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'User/RequestCancel',
                data : accRequest
            })
            return response
        } catch (error) {
            throw new Error('Unable to send cancellation')
        }
    },
    requestChange: async (accRequest) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'User/RequestChange',
                data : accRequest
            })
            return response
        } catch (error) {
            throw new Error('Unable to send change request')
        }
    },
    sendQuery: async (accRequest) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'User/Query',
                data : accRequest
            })
            return response
        } catch (error) {
            throw new Error('Unable to send query')
        }
    },
    startTranscribing: async (payload) => {

        const reqBody = {
            Speakers: payload.speakers
        }

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Recording/' + payload.collectionID + '/' + payload.recordingID + '/StartTranscribe' ,
                data: reqBody,
            })
            return response
        } catch (error) {
            throw new Error('Unable to start transcription')
        }
    },
    shareRecording: async (collectionID, recordingID, payload) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Recording/' + collectionID + '/' + recordingID + '/Share' ,
                data: payload,
            })
            return response
        } catch (error) {
            throw new Error('Unable to share recording')
        }
    },
    removeSharing: async (collectionID, recordingID) => {

        try {
            const response = await axios({
                method: 'delete',
                url: baseURL + 'Recording/' + collectionID + '/' + recordingID + '/Share' ,
            })
            return response
        } catch (error) {
            throw new Error('Unable to remove sharing')
        }

    },
    generateReferralCode: async () => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Code/Create',
            })
            return response
        } catch (error) {
            throw new Error('Unable to generate code')
        }
    },
    redeemReferralCode: async (code) => {

        try {
            const response = await axios({
                method: 'post',
                url: baseURL + 'Code/Redeem/' + code,
            })

            return response

        } catch (error) {
            throw new Error('Unable to redeem code')
        }

    },
    downloadCaptions: async (rec) => {

        try {
            const response = await axios({
                method: 'get',
                url: `${baseURL}Captions/${rec.CollectionID}/${rec.ItemID}`
            })

        return response

        } catch (error) {
            throw new Error('Unable to get captions')
        }

    }
    // getNotesID: async (payload) => {


    //     //payload = {
    //     //     collectionID:
    //     //     recordingID:
    //     //  // notesID: (for the others)
    //     // noteID
    //     // // note:
    //     // }

    //     try {
    //         const response = await axios.get(baseURL + 'Notes/' + payload.collectionID + "/" + payload.recordingID + "/NotesID")
    //         return response
    //     } catch (error) {
    //         throw new Error('Unable to retrieve notes ID Information')
    //     }

    // },
    // getNotes: async (payload) => {

    //     try {
    //         const response = await axios.get(baseURL + 'Notes/' + payload.collectionID + '/' + payload.notepadID)
    //         return response
    //     } catch (error) {
    //         throw new Error('Unable to retrieve notes')
    //     }

    // },
    // addNote: async (payload) => {

    //     try {
    //         const response = await axios({
    //             method: 'post',
    //             url: baseURL + 'Notes/' + payload.collectionID + '/' + payload.notepadID,
    //             data : payload.note
    //         })
    //         return response
    //     } catch (error) {
    //         throw new Error('Unable to add note')
    //     }

    // },
    // updateNote: async (payload) => {

    //     try {
    //         const response = await axios({
    //             method: 'post',
    //             url: baseURL + 'Notes/' + payload.collectionID + '/' + payload.notepadID + '/' + payload.noteID,
    //             data : payload.note
    //         })
    //         return response
    //     } catch (error) {
    //         throw new Error('Unable to update note')
    //     }

    // },
    // deleteNote: async (payload) => {

    //     try {
    //         const response = await axios({
    //             method: 'delete',
    //             url: baseURL + 'Notes/' + payload.collectionID + '/' + payload.notepadID + '/' + payload.noteID,
    //         })
    //         return response
    //     } catch (error) {
    //         throw new Error('Unable to delete note')
    //     }
    // },

}
