import Vue from 'vue'
import Vuex from 'vuex'

import collections from './store/collections'
import recording from './store/recording'
import notes from './store/notes'
// import recorder from './store/recorder'
import display from './store/display'
import user from './store/user'
import errors from './store/errors'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules : {
    collections,
    recording,
  //  recorder,
    notes,
    errors,
    display,
    user
  }
})
