/* eslint-disable */

import Vue from 'vue'
import Router from 'vue-router'
// import Home from './views/Home.vue'
// import Collection from './views/Collection.vue'
// import Uploads from './views/Uploads.vue'
// import Account from './views/Account.vue'
import { signOut, isSignedIn } from './auth.js';
// import { Auth } from 'aws-amplify';
// import UserInfoApi from './requests.js';
import store from './store'
import Login from './views/Login.vue'

Vue.use(Router)

function requireAuth(to, from, next) {

  isSignedIn()
  .then(() => next())
  .catch(()=> {

    if (to.name == 'login' || to.name == 'signup') {
      next()
    } else {
      next({name: 'login'})
    }
  })
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      // component: Home,
      component: () => import('./views/Home.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/logout', beforeEnter(to, from, next){
        signOut()
        .then(() =>  next({name: 'login'}))
        .catch(()=> next(from))

      }
    },
    {
      path: '/collection/:collID',
      name: 'collection',
      // component: Collection,
      component: () => import('./views/Collection.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/collection/:collID/upload',
      name: 'newUpload',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      beforeEnter: requireAuth,
      component: () => import('./views/NewUpload.vue')
    },
    {
      path: '/recording/:collID/:recID',
      name: 'recording',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      beforeEnter: requireAuth,
      component: () => import('./views/Recording.vue')
    },
    {
      path: '/uploads',
      name: 'Uploads',
      // component: Uploads,
      component: () => import('./views/Uploads.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/account',
      name: 'account',
      // component: Account,
      component: () => import('./views/Account.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
      // component: Login,
      // beforeEnter: requireAuth
    },
    // {
    //   path: '/record',
    //   name: 'record',
    //   component: () => import('./views/Record.vue'),
    //   // component: Login,
    //   beforeEnter: requireAuth,
    // },

  ]
})
